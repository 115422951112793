.og-examples__editor {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 16px;
}

.og-examples__toolbar {
    position: relative;
    float: right;
    margin-top: 1px;
}

.og-examples__code {
    width: 100%;
    height: calc(100% - 73px);
    padding: 0;
    margin: 0;
    position: absolute;
    overflow: auto;
}