.og-sandbox_examples {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.og-sandbox_examples .mb-3 {
    padding: 0 18px 0px 14px;
}

.og-sandbox_list {
    width: 300px;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    float: left;
}

.og-sandbox_list a {
    width: 100%;
    position: relative;
    display: inline-block;
}

.og-sandbox_list a.active button {
    background-color: #0d6efd;
    color: white;
}

.og-sandbox_list a button {
    width: calc(100% - 2px);
    padding: 12px;
    border: none;
    margin: 1px;
    cursor: pointer;
}